if (location.hostname !== 'localhost' && location.protocol !== 'https:') {
  location.href =
    'https:' + window.location.href.substring(window.location.protocol.length);
}

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store/store';
import './assets/css/scss/main.scss';
// import VeeValidate from 'vee-validate';
import saveAs from 'file-saver';
import Notifications from 'vue-notification';
import axios from '../Axios.js';
import { URLS } from './constants';
import { get } from 'lodash';
import './registerServiceWorker';
import { $getEquivalentUnit , $getDate} from './libs/Classes/utils';
import * as VeeValidate from "vee-validate";
import {
  removeAuthInLocalStorage,
} from './helperFunctions';



// Now tell vue to use this plugin

Vue.use(VeeValidate);

// import LogRocket from 'logrocket';

// Vue.use(VeeValidate);
Vue.use(Notifications);
Vue.prototype.$saveAs = saveAs;

// if (location.hostname !== "localhost") {
//     LogRocket.init('qfnmm7/first-step', {
//         console: {
//             shouldAggregateConsoleErrors: true,
//         }
//     });
// }

Vue.prototype.$generateId = function() {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
};

Vue.prototype.$outline = {
  evaluations: {
    groups: {
      assessors: {
        footbaths: {
          mixes: null,
        },
      },
    },
  },
  clients: {
    contacts: null,
    dairy_locations: null,
  },
  // "medias": [],
};
Vue.prototype.$outlineTotal = 12;

// (function setCurrentTabOpen() {
//   /* we are using local storage to track how many tabs are open currently in the browser
//      1. When firstly user landed up on the website, we increament the localstorage count to 1.
//      2. If user reload the website we decrment the tabcount so user can reload without any error.
//      3. If user open second tab then we get the current tab count from local and add the current
//      tab into it , now it will be 2 so and our app, and our app respond accordingly that user can't open multiple tabs.
//      */

//   const tabCount = +localStorage.getItem('activeTabCount') || 0;
//   const updatedTabCount = +tabCount + 1;

//   Vue.prototype.$tabs = updatedTabCount > 1;

//   localStorage.setItem('activeTabCount', updatedTabCount);
// })();

// function decreaseTabCount(e) {
//   e.preventDefault();
//   const tabCount = +localStorage.getItem('activeTabCount') || 0;
//   const updatedTabCount = tabCount > 0 ? tabCount - 1 : 0;
//   localStorage.setItem('activeTabCount', updatedTabCount);
//   Vue.prototype.$tabs = updatedTabCount;
//   window.onbeforeunload = null;
// }

// window.addEventListener('beforeunload', decreaseTabCount);

// localStorage.openpages = Date.now();
// console.log('key', e.key);
// var onLocalStorageEvent = e => {
//   if (e.key == 'openpages') {
//     // Emit that you're already available.
//     localStorage.page_available = Date.now();
//     // store.state.isMultipleTabs = true;
//   }
//   if (e.key == 'page_available') {
//     // alert('One more page already open');
//     console.log('settitng tabs value');
//     // store.state.isMultipleTabs = true;
//   }
// };
// window.addEventListener('storage', onLocalStorageEvent, false);

Vue.prototype.$scrollTop = function() {
  let element = document.getElementById('top');
  let top = element.offsetTop;
  window.scrollTo(0, top);
};

Vue.prototype.$isNumber = function(evt) {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (
    charCode > 31 &&
    (charCode < 48 || charCode > 57) &&
    (charCode !== 46 || evt.target.getAttribute('inputmode') === 'numeric')
  ) {
    evt.preventDefault();
  } else {
    return true;
  }
};

Vue.prototype.$isRatio = function(evt) {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (
    charCode > 31 &&
    charCode !== 37 && (charCode < 48 || charCode > 57) &&
    charCode !== 58
  ) {
    evt.preventDefault();
  } else {
    return true;
  }
};

Number.prototype.toPercentage = function(roundPoint) {
  if (roundPoint === undefined) roundPoint = 0;
  return parseFloat((this.valueOf() * 100).toFixed(roundPoint));
};

Number.prototype.toDecimal = function(roundPoint) {
  if (roundPoint === undefined) roundPoint = 0;
  if (roundPoint === false) return this.valueOf();
  let newDecimalValue = this.valueOf().toFixed(roundPoint);

  // if last value in new decimal value is 0, then change it to a 1 because we don't want to lose the number of decimal places
  if (/(?:[0-9]+)\.(?:[0-9]+)?0$/.test(newDecimalValue)) {
    newDecimalValue = newDecimalValue.replace(/.$/, '1');
  }

  return parseFloat(newDecimalValue);
};

Number.prototype.toCurrency = function() {
  return this.valueOf().toFixed(2);
};

Number.prototype.countDecimals = function() {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split('.')[1].length || 0;
};

function decimalAdjust(type, value, exp) {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

if (!Math.round10) {
  Math.round10 = function(value, exp) {
    return decimalAdjust('round', value, exp);
  };
}
if (!Math.floor10) {
  Math.floor10 = function(value, exp) {
    return decimalAdjust('floor', value, exp);
  };
}
if (!Math.ceil10) {
  Math.ceil10 = function(value, exp) {
    return decimalAdjust('ceil', value, exp);
  };
}

Vue.prototype.$getEquivalentUnit = $getEquivalentUnit

export const languages = [
  {
  code: 'en',
  numberFormat: 'en-US'
},
{
  code: 'es',
  numberFormat: 'es-ES'
},
{
  code: 'fr',
  numberFormat: 'fr-FR'
},
{
  code: 'tr',
  numberFormat: 'de-DE'
},
{
  code: 'de',
  numberFormat: 'de-DE'
},
{
  code: 'pt',
  numberFormat: 'pt-PT'
},
{
  code: 'ch',
  numberFormat: 'zh-ZH'
},
{
  code: 'jp',
  numberFormat: 'ja-JP'
},
{
  code: 'pl',
  numberFormat: 'pt-PT'
},
{
  code: 'it',
  numberFormat: 'de-DE'
},
{
  code: 'nl',
  numberFormat: 'nl-NL'
},
{
  code: 'ru',
  numberFormat: 'sv-SE'
},
]


Vue.filter('numberFormat', function (value) {
  const code = self.app.$store.state.i18n.locale;
  languages.find((lang)=> lang.code === code).numberFormat
 
  const formatterNumber = new Intl.NumberFormat(locale)
 return formatterNumber.format(Number(value))
})


Vue.prototype.$numberFormat = function(value) {

  const code = self.app.$store.state.i18n.locale;
  const localeFormat = languages.find((lang) => lang.code === code)?.numberFormat || 'en-US';
  const formatterNumber = new Intl.NumberFormat(localeFormat);
  
  return formatterNumber.format(Number(value));
};
Vue.prototype.$isAdmin = function() {
  if (router.currentRoute.fullPath.includes('/admin/')) {
    return true;
  } else {
    return false;
  }
};

Vue.prototype.$goToLameness = function(path, event) {
  if (event || !$store.state.getters.isOnline) event.preventDefault();
  axios.post(URLS.LIBRARY, { path }).then(response => {
    window.open(get(response, 'data.data.path', ''), '_blank');
  });
};

// Axios response interceptor
axios.interceptors.response.use(response => {
  // Handle responses
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    console.log('this.$route.path', router.currentRoute.path)
    if(router?.currentRoute?.path == '/reset-password/' || router?.currentRoute?.path == '/verify-account'){
      console.log('this.$route.path', router.currentRoute)
      return
    }else{
      // removeAuthInLocalStorage();
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('expireAt');
      localStorage.removeItem('role');
      localStorage.removeItem('openpages');
      localStorage.removeItem('lang');
      router.push('/login');
    }
  }
  return Promise.reject(error);
});

Vue.config.productionTip = false;
window.app = new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app');