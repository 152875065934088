<!-- @format -->

<template>
  <!--
    @TODO:
    - what kind of feedback do we want when a user adds or modifies a group?
    - close an accordion when opening another
    - when editing fields in, make it so that the listed information doesn't get updated
     -->
  <div class="container main-container" v-if="evaluation">
    <h1 class="h1">{{ "Groups" | translate }}</h1>
    <div v-if="evaluation.groups">
      <div id="columns" class="row mx-0">
        <div class="offset-1 col-md-8 d-none d-lg-block heading">
          {{ "Group/Pen Name" | translate }}
        </div>
        <div class="col-md-3 d-none d-lg-block heading">
          {{ "Assessors" | translate }}
        </div>
        <div class="col-md-6 d-none d-lg-block heading">
          {{ "Group/Pen Size" | translate }}
        </div>
        <div class="col-md-6 d-none d-lg-block heading">
          {{ "Type of Animal" | translate }}
        </div>
      </div>
      <group
        v-for="(group, index) in evaluation.groups"
        :key="index"
        id="accordion"
        role="tablist"
        :group="group"
        :evaluation="evaluation"
      >
        <div class="accordion-heading--wrapper">
          <div
            :id="['groups-' + index]"
            class="accordion-heading row mx-0"
          >
            <div
              class="open-eval col-3 col-sm-2 col-lg-1"
              data-toggle="collapse"
              data-parent="#accordion"
              :href="['#groupsAccordion' + index]"
              aria-expanded="false"
              :aria-controls="['groupsAccordion' + index]"
            >
              <i
                class="fa fa-angle-down fa-angle-down--eval"
                aria-hidden="true"
              ></i>
              <i
                class="fa fa-angle-up fa-angle-up--eval"
                aria-hidden="true"
              ></i>
            </div>
            <div class="col-lg-8 col-15 heading">
              {{ group.name }}
            </div>
            <div class="col-md-3 heading d-none d-lg-block">
              {{ group.assessor_list.length }}
            </div>
            <div class="col-md-6 heading d-none d-lg-block">
              {{ group.data.size }}
            </div>
            <div class="col-md-4 heading d-none d-lg-block">
              {{ animalType[group.data.type_of_animal] | translate }}
            </div>
            <div class="col-lg-2 col-sm-6 col-5 heading">
              <modal-ellipsis :value="index" class="pull-right">
                <template slot="modal-title">
                  <span>{{
                    "What Would You Like To Do?" | translate
                  }}</span>
                </template>
                <template slot="modal-content">
                  <a
                    class="btn btn-full btn-full--modal"
                    data-dismiss="modal"
                    data-toggle="modal"
                    :data-target="['#areYouSure-' + index]"
                    >{{ "Delete" | translate }}</a
                  >
                  <div class="row">
                    <div class="col-md-24 modal-bottom">
                      <a
                        href="#"
                        class="btn-cancel-modal mt-2"
                        data-dismiss="modal"
                      >
                        {{ "Cancel" | translate }}
                      </a>
                    </div>
                  </div>
                </template>
              </modal-ellipsis>

              <div
                class="modal fade"
                :id="['areYouSure-' + index]"
                tabindex="-1"
                role="dialog"
                :aria-labelledby="['areYouSure-' + index]"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content edit-modal">
                    <h2 class="h2 h2--modal text-center mt-3 mt-md-0">
                      {{ "Are you sure?" | translate }}
                    </h2>
                    <div class="modal-body my-0">
                      <a
                        @click="deleteGroup(group.id)"
                        href="#"
                        data-dismiss="modal"
                        class="btn btn-primary btn-full mb-2 d-block"
                      >
                        {{ "Yes" | translate }}
                      </a>
                      <a
                        href="#"
                        data-dismiss="modal"
                        class="btn btn-secondary btn-full btn-full--modal mb-2"
                      >
                        {{ "No" | translate }}
                      </a>
                      <div class="row mt-2">
                        <div class="col-md-24 modal-bottom">
                          <a
                            href="#"
                            class="btn-cancel-modal"
                            data-dismiss="modal"
                          >
                            {{ "Cancel" | translate }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end modal -->
            </div>
            <!-- end modal column -->
          </div>
          <!-- end accordion-heading -->
        </div>
        <!-- end accordion-heading-wrapper -->

        <div
          :id="['groupsAccordion' + index]"
          class="collapse pt-3 pb-4"
          role="tabpanel"
        >
          <edit-group
            :index="index"
            :unit="evaluation.data.measurements"
            :groupId="group.id"
            :evaluationId="paramEvaluationId"
          >
          </edit-group>
        </div>
      </group>
    </div>
    <div class="card-body card-body--group mt-4">
      <div class="card card--grey">
        <h2 class="h2">{{ "Add Group" | translate }}</h2>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="mb-1"
                >{{ "Evaluation Name" | translate }}
                <span class="required">*</span></label
              >
              <input
                type="text"
                class="form-control"
                v-model="evaluation.name"
                name="evaluation_name"
                data-vv-as="Evaluation Name"
                v-validate="'required'"
                disabled
              />
              <span
                v-show="errors.has('evaluation_name')"
                class="text-danger"
              >
                <small>{{
                  "The Evaluation Name field is required." | translate
                }}</small>
              </span>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="mb-1"
                >{{ "Group/Pen Name" | translate }}
                <span class="required">*</span></label
              >
              <input
                type="text"
                class="form-control"
                name="group_name"
                v-model="newGroup.name"
                data-vv-as="Group/Pen Name"
                v-validate="'required'"
              />
              <span
                v-show="errors.has('group_name')"
                class="text-danger"
              >
                <small>{{
                  "The Group/Pen Name is required." | translate
                }}</small>
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-1"
                >{{ "Group/Pen Size" | translate }}
                <span class="required">*</span>
              </label>
              <currency-input
                key="group_size"
                  name="group_size"                  
                  v-model="newGroup.data.size"
                  data-vv-as="Group/Pen Size"
                  v-validate="'required'"
                  :options="{
                    currency: 'USD',
                    precision: 0,
                  }"
                />
              <span
                v-show="errors.has('group_size')"
                class="text-danger"
              >
                <small>{{
                  "The Group/Pen Size field is required." | translate
                }}</small>
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-1"
                >{{ "Type of Animal" | translate }}
                <span class="required">*</span>
              </label>
              <select
                name="group_type_of_animal"
                v-model="newGroup.data.type_of_animal"
                class="form-control"
                data-vv-as="Type of Animal"
                v-validate="'required'"
              >
                <option :value="null" disabled>
                  {{ "Select" | translate }}
                </option>
                <option value="close-up">
                  {{ "Close-up" | translate }}
                </option>
                <option value="far-off">
                  {{ "Far-off" | translate }}
                </option>
                <option value="fresh">
                  {{ "Fresh" | translate }}
                </option>
                <option value="lactating">
                  {{ "Lactating" | translate }}
                </option>
                <option value="heifers-first-lactation">
                  {{ "Heifers (First Lactation)" | translate }}
                </option>
                <option value="heifers-growing">
                  {{ "Heifers (Growing)" | translate }}
                </option>
                <option value="mixed">
                  {{ "Mixed" | translate }}
                </option>
              </select>
              <span
                v-show="errors.has('group_type_of_animal')"
                class="text-danger"
              >
                <small>{{
                  "The Type of Animal field is required." | translate
                }}</small>
              </span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="mb-1"
                >{{
                  $t("Average Cow Size") +
                  " (" +
                  $t(
                    $getEquivalentUnit(
                      evaluation.data.measurements,
                      "kg"
                    )
                  )
                }})*</label
              >
              <select
                name="cow_size"
                v-model="newGroup.data.cow_size"
                class="form-control"
                data-vv-as="Avg. Cow Size"
                v-validate="'required'"
              >
                <option disabled :value="null">
                  {{ "Select" | translate }}
                </option>
                <option
                  v-for="(cowSize, index) in cowSizes"
                  :value="cowSize.value"
                  :key="index"
                >
                  {{ cowSize.label }}
                </option>
              </select>
              <span
                v-show="errors.has('cow_size')"
                class="text-danger"
              >
                <small>{{
                  "The Average Cow Size field is required."
                    | translate
                }}</small>
              </span>
            </div>
          </div>
          <div class="col-md-6" v-if="showMilkProductionField">
            <div class="form-group">
              <label class="mb-1"
                >
                  {{
                    $t('Milk Production') +
                      ' (' +
                      $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) +
                      $t('/day)')
                  }}
                *</label
              >
              <currency-input
                key="group_milk_prod"
                  name="group_milk_prod"                  
                  v-model="displayMilkProduction"
                  :data-vv-as="
                  $t('Milk Production') +
                  ' (' +
                  $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) +
                  $t('/day)')
                "
                  v-validate="'required'"
                  :options="{
                    currency: 'USD',
                    precision: {min: 0, max :2},
                  }"
                />
              <span
                v-show="errors.has('group_milk_prod')"
                class="text-danger"
              >
                <small>{{
                  "The Milk Production field is required." | translate
                }}</small>
              </span>
            </div>
          </div>

          <div class="col-md-6">
             <label for="mineral" class="mb-1">
              {{ "Zinpro Products Used?" | translate }}
                <modal-info :value="'mineral'">
                  <template slot="modal-content">
                    <h2 class="h2 pt-3 pb-2 px-3 text-center">
                      {{
                             `Please indicate if any Zinpro products are fed within this pen.
                              If yes, select “Details” to select which products are used. 
                              You can select multiple products and indicate the inclusion level if known. 
                              Inclusion level is not required.`
                               | translate
                           }}
                    </h2>
                    <div class="row modal-bottom pb-3">
                      <a
                        href="#"
                        class="btn-cancel-modal d-flex justify-content-center"
                        data-dismiss="modal"
                        >{{ "Cancel" | translate }}</a
                      >
                    </div>
                  </template>
                </modal-info>
              </label>
            <div
              style="
                display: flex;
                gap: 10px;
                align-items: flex-start;
              "
            >
              <select
                name="mineral"
                v-model="newGroup.data.isUsedZinproProduct"
                class="form-control"
                data-vv-as="Avg. Cow Size"
              >
                <option :value="null" selected disabled>
                    {{ "Select" | translate }}
                  </option>
                <option :value="true">{{ "Yes" | translate }}</option>
                <option :value="false" :disabled="!!Object.keys(newGroup.data.selectedMinerals).length">{{ "No" | translate }}</option>
              </select>
              <button
                v-if="newGroup.data.isUsedZinproProduct"
                type="button"
                class="btn btn-sm mr-4 btn-full--sm"
                style="padding: 8px 10px"
                @click="showMineralModal = true"
              >
                {{ "Details" | translate }}
              </button>
            </div>
          </div>

          <mineral-detail-modal
            :is-open="showMineralModal"
            @close="showMineralModal = false"
            @delete-mineral="(minerals) => newGroup.data.selectedMinerals = minerals"
            :selected-minerals="newGroup.data.selectedMinerals"
          >
          </mineral-detail-modal>
        </div>
        <div class="row">
          <div class="col-md-24">
            <div class="form-group">
              <label class="mb-1"
                >{{ "Personal Notes" | translate }}
                <modal-info :value="'personal-notes-sm'">
                  <template slot="modal-content">
                    <h2 class="h2 pt-3 pb-2 px-3 text-center">
                      {{
                        "Personal Notes: Not included when outputting reports."
                          | translate
                      }}
                    </h2>
                    <div class="row modal-bottom pb-3">
                      <a
                        href="#"
                        class="btn-cancel-modal d-flex justify-content-center"
                        data-dismiss="modal"
                        >{{ "Cancel" | translate }}</a
                      >
                    </div>
                  </template>
                </modal-info>
              </label>
              <textarea
                class="form-control"
                name="group_personal_notes"
                v-model="newGroup.personal_notes"
                data-vv-as="Personal Notes"
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-md-24">
            <div class="form-group">
              <div class="row">
                <label class="mr-3"
                  >{{ "Select Assessors" | translate }}
                  <span class="required">*</span>
                </label>
                <label
                  class="label-checkbox label-checkbox--select-all"
                >
                  <input
                    type="checkbox"
                    name="checkAll"
                    id="checkAll"
                    :checked="CheckAll"
                    value="1"
                    @click="checkAll()"
                  />
                  <span class="checkbox"></span>
                </label>

                <label for="checkAll" class="label--font-thin">{{
                  "Select/Deselect All" | translate
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade show modal-overlay"
          tabindex="-1"
          id="info-transition-alert"
          role="dialog"
          style="display: block"
          v-if="showTransitionAlert"
        >
          <div
            class="modal-dialog modal-dialog--edit"
            role="document"
          >
            <div class="modal-content edit-modal pt-0 px-0">
              <div class="modal-body my-0 py-0 px-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  style="margin: 0 5px"
                  @click="showTransitionAlert = false"
                >
                  &times;
                </button>
                <h2 class="h2 pb-2 pt-3 px-3 text-center">
                  {{
                    "If you want to export data for the Transition assessor you must first"
                      | translate
                  }}:
                </h2>
                <ul>
                  <li>
                    <p class="p--md px-3">
                      •
                      {{
                        "Add multiple groups for a single Evaluation to allow for comparison reporting."
                          | translate
                      }}
                    </p>
                  </li>
                  <li>
                    <p class="p--md px-3">
                      •
                      {{
                        "It is advised to complete the Locomotion assessor before beginning the Transition assessor."
                          | translate
                      }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row assessors-list">
          <div
            class="col-md-12 col-lg-8"
            v-for="(assessor, index) in assessorTypes"
            :key="index"
          >
            <div class="form-group form-group--word-wrap">
              <label class="label-checkbox">
                <input
                  type="checkbox"
                  name="assessors"
                  :id="'h' + assessor.id"
                  @change="checkIndividual"
                  v-model="newGroup.assessor_list"
                  :value="assessor.id"
                />
                <span class="checkbox"></span>
              </label>

              <label
                :for="'h' + assessor.id"
                class="label--font-thin d-inline"
              >
                {{ assessor.name | translate }}
              </label>
              <modal-info :value="assessor.id">
                <template slot="modal-content">
                  <h2 class="h2 pb-2 pt-3 px-3 text-center">
                    {{ assessor.name | translate }}
                  </h2>
                  <p
                    class="p--md px-3"
                    v-for="(
                      description, index
                    ) in assessor.description"
                    :key="index"
                  >
                    {{ description | translate }}
                  </p>
                  <div class="row modal-bottom pb-3 mt-3">
                    <a
                      href="#"
                      class="btn-cancel-modal d-flex justify-content-center"
                      data-dismiss="modal"
                      >{{ "Cancel" | translate }}</a
                    >
                  </div>
                </template>
              </modal-info>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col mt-2 mb-3">
            <button
              class="btn btn-primary btn-full--sm"
              @click="addNewGroup()"
            >
              {{ "Add Group" | translate }}
            </button>
          </div>
        </div>

        <span
          v-show="checkboxPermission === true"
          class="text-danger"
        >
          <small>{{
            "You need to click at least one of the checkboxes!"
              | translate
          }}</small>
        </span>

        <div class="row">
          <div class="col-24">
            <div
              v-if="errorMessage"
              class="alert alert-danger mt-3"
              role="alert"
            >
              {{ "Please fill out the required fields!" | translate }}
            </div>
            <div
              v-if="confirmMessage"
              class="alert alert-primary mt-3"
              role="alert"
            >
              {{ "A new group has been added!" | translate }}
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>

    <modal-confirm
      v-if="showModal"
      @close="showModal = false"
      :link="url"
      :headline="'Your new group has not been saved' | translate"
      :description="
        'Would you like continue without saving your group? If you wish to further edit this group, click &quot;No&quot; to exit this pop up screen and continue editing. Once you are finished editing this group click the &quot;Add Group&quot; button.'
          | translate
      "
      :onClick="propFunction"
    ></modal-confirm>
    <!-- end card-body -->
    <div class="card-footer pb-0">
      <div class="row d-flex flex-row">
        <div class="order-2 order-md-1 col-md-8">
          <button
            @click="
              checkIfAllItemsAreNull(
                '/users/' +
                  $route.params.userId +
                  '/evaluations/' +
                  paramEvaluationId
              )
            "
            class="btn btn-secondary btn-full--sm mb-2 mb-md-0"
          >
            {{ "Back" | translate }}
          </button>
        </div>
        <div
          class="order-1 order-md-2 col-md-16 d-flex flex-md-row flex-column-reverse justify-content-end mb-2 mb-md-0"
        >
          <button
            :class="[
              'btn',
              'btn-primary',
              'btn-full--sm',
              'float-right',
              'ml-0',
              'ml-md-4',
              'mt-2',
              'mt-md-0',
              'order-md-2',
              {
                disabled:
                  evaluation.groups === undefined ||
                  Object.values(evaluation.groups).length === 0,
              },
            ]"
            @click="
              Object.values(evaluation.groups).length > 0
                ? checkIfAllItemsAreNull(
                    '/users/' +
                      $route.params.userId +
                      '/evaluations/' +
                      paramEvaluationId +
                      '/order'
                  )
                : null
            "
          >
            {{ "Next" | translate }}
          </button>
          <!-- :disabled="errors.any() || !isNewGroupCompleted"  -->
        </div>
      </div>
    </div>
  </div>
  <!-- end container -->
</template>

<script>
/**
 *  @TODO what kind of feedback do we want when a user adds or modifies a group?
 *  @TODO Do users need to check at least one assessor before continuing?
 */

import _ from "lodash";
import editGroup from "@/components/evaluations/editGroup.vue";
import ModalEllipsis from "@/components/misc/modal-ellipsis.vue";
import ModalInfo from "@/components/misc/modal-info.vue";
import ModalConfirm from "@/components/misc/modal-confirm.vue";
import Group from "@/components/misc/group.vue";
import { initializeAssessorDataByType } from "../../helperFunctions";

import convert from "convert-units";

let $ = (window.jQuery = require("jquery"));

import Evaluation from "@/libs/Classes/Evaluation.js";
import GroupClass from "@/libs/Classes/Group.js";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  GET_EVALUATION_BY_ID,
  GET_CREATE_GROUP,
  GET_DELETE_GROUP,
  GET_EVALUATION_BY_ID_GETTER,
  GET_UPDATE_EVALUATION,
} from "../../store/types";
import MineralDetailModal from "@/components/misc/mineral-detail-modal.vue";
import CurrencyInput from "@/components/common/CurrencyInput.vue";

export default {
  components: {
    editGroup,
    ModalEllipsis,
    ModalConfirm,
    Group,
    ModalInfo,
    MineralDetailModal,
    CurrencyInput
  },
  data() {
    return {
      showMineralModal: false,
      evaluation: null,
      paramEvaluationId: this.$route.params.evaluationId,
      evaluationClass: null,
      groupClass: null,
      isCheckAll: false,
      confirmMessage: false,
      tab: 0,
      propFunction: null,
      previous: null,
      show: false,
      url: null,
      showModal: false,
      errorMessage: false,
      checkboxPermission: false,
      animalType: {
        "close-up": "Close-up",
        "far-off": "Far-off",
        fresh: "Fresh",
        lactating: "Lactating",
        "heifers-first-lactation": "Heifers (First Lactation)",
        "heifers-growing": "Heifers (Growing)",
        mixed: "Mixed",
      },
      show_groups: false,
      default_group: {
        id: null,
        name: "",
        date_created: new Date().getTime(),
        date_updated: new Date().getTime(),
        assessor_list: [],
        data: {
          cow_size: null,
          milkProduction: null,
          type_of_animal: null,
          size: "",
          isUsedZinproProduct: null,
          selectedMinerals:{}
        },
      },
      newGroup: null,
      CheckAll: false,
      showTransitionAlert: false,
      showedTransitionAlert: false,
      imperialMilkProduction: null,
      metricMilkProduction: null,
      hiddenImperialMilkProduction: null,
      hiddenMetricMilkProduction: null,
      originalMeasurement: null,
      userEnteredDecimal: false,
    };
  },
  beforeRouteLeave: function (to, from, next) {
    if (
      to ===
      "/users/" +
        this.$route.params.userId +
        "/evaluations/" +
        this.paramEvaluationId +
        "/order"
    ) {
      if (
        this.evaluation.groups === undefined ||
        this.evaluation.groups.length === 0
      ) {
        next(false);
      }
    }
    next();
  },
  watch: {
    evaluations(GROUPS) {
      console.log("group has been added successfully.");
      this.evaluation = this[GET_EVALUATION_BY_ID_GETTER](
        this.paramEvaluationId
      );
    },
    deep: true,
  },
  computed: {
    ...mapState({
      evaluations: (state) => state.evaluations.evaluations,
      // evaluation: state => state.evaluations.evaluation,
    }),
    ...mapGetters("evaluations", [GET_EVALUATION_BY_ID_GETTER]),
    displayMilkProduction: {
      cache: false,
      get() {
        return this.groupClass.getShowMilkProduction();
      },
      set(newValue) {
        return this.groupClass.setMilkProduction(newValue);
      },
    },

    cowSizes() {
      const cow_sizes_in_kg = [
        408.2, 498.9, 589.6, 680.3, 771, 861.8, 952.5,
      ];
      let converted_cow_sizes = [];

      for (let i = 0; i < cow_sizes_in_kg.length; i++) {
        if (i !== 0) {
          let low_converted_value = parseInt(
            convert(cow_sizes_in_kg[i - 1])
              .from("kg")
              .to(
                this.$getEquivalentUnit(
                  this.evaluation.data.measurements,
                  "kg"
                )
              )
              .toFixed(0)
          );

          let high_converted_value = parseInt(
            convert(cow_sizes_in_kg[i])
              .from("kg")
              .to(
                this.$getEquivalentUnit(
                  this.evaluation.data.measurements,
                  "kg"
                )
              )
              .toFixed(0)
          );

          converted_cow_sizes.push({
            value: cow_sizes_in_kg[i - 1] + "-" + cow_sizes_in_kg[i],
            label: low_converted_value + "-" + high_converted_value,
          });
        }
      }

      return converted_cow_sizes;
    },
    showMilkProductionField() {
      // this.newGroup.assessor_list.includes('3') &&
      return [
        "fresh",
        "lactating",
        "heifers-first-lactation",
        "mixed",
      ].includes(this.newGroup.data.type_of_animal);
    },
    displayMilkProduction: {
      get() {
        if (this.evaluation.data.measurements === 'imperial') {
          return this.imperialMilkProduction !== null ? this.imperialMilkProduction : this.hiddenImperialMilkProduction;
        } else {
          return this.metricMilkProduction !== null ? this.metricMilkProduction : this.hiddenMetricMilkProduction;
        }
      },
      set(value) {
        if (this.evaluation.data.measurements === 'imperial') {
          this.imperialMilkProduction = value;
          this.metricMilkProduction = Math.round(value * 0.45359237);
          this.newGroup.data.metricMilkProduction = this.metricMilkProduction
          this.newGroup.data.imperialMilkProduction = value

          
        } else {
          this.metricMilkProduction = value;
          this.imperialMilkProduction = Math.round(value / 0.45359237);
          this.newGroup.data.imperialMilkProduction = this.imperialMilkProduction
          this.newGroup.data.metricMilkProduction = value

        }
        this.originalMeasurement = this.evaluation.data.measurements;
        this.groupClass.setMilkProduction(value);
        
      }
    },
  },
  methods: {
    ...mapActions("evaluations", [
      GET_EVALUATION_BY_ID,
      GET_UPDATE_EVALUATION,
    ]),
    ...mapActions("groups", [GET_CREATE_GROUP, GET_DELETE_GROUP]),
    initializeNewGroup() {
      this.newGroup = _.cloneDeep(this.default_group);
      this.newGroup.id = this.$generateId();
      this.newGroup.date_created = new Date().getTime();
      this.newGroup.date_updated = new Date().getTime();
      
      // Reset milk production values
      this.imperialMilkProduction = null;
      this.metricMilkProduction = null;
      this.hiddenImperialMilkProduction = null;
      this.hiddenMetricMilkProduction = null;
      this.originalMeasurement = null;
      this.userEnteredDecimal = false;
      this.newGroup.data.milkProduction = null;

      this.evaluationClass = new Evaluation(this, this.evaluation);
      this.groupClass = new GroupClass(
        this,
        this.evaluationClass,
        this.newGroup
      );
    },
    checkIfAllItemsAreNull(url) {
      let group = _.cloneDeep(this.default_group);
      group.id = this.newGroup.id;
      group.date_created = this.newGroup.date_created;
      group.date_updated = this.newGroup.date_updated;
      this.url = url;
      // otherwise the id is null, making the fields not equal
      if (!_.isEqual(this.newGroup, group)) {
        this.propFunction = this.setEvaluationOrder;
        this.showModal = true;
      } else {
        this.setEvaluationOrder(url);
      }
    },
    setEvaluationOrder() {
      if (this.url.includes("/order")) {
        let assessorTypes = [];
        for (let i = 0; i < this.evaluation.groups.length; i++) {
          for (
            let j = 0;
            j < this.evaluation.groups[i].assessor_list.length;
            j++
          ) {
            let assessorTypeExists = assessorTypes.find(
              (assessorTypeId) => {
                return (
                  assessorTypeId ===
                  this.evaluation.groups[i].assessor_list[j]
                );
              }
            );

            if (assessorTypeExists === undefined) {
              assessorTypes.push(
                this.$store.state.assessorTypes.find(
                  (assessorType) => {
                    return (
                      assessorType.id ==
                      this.evaluation.groups[i].assessor_list[j]
                    );
                  }
                ).id
              );
            }
          }
        }
        if (
          this.evaluation.order &&
          this.evaluation.order.length < 1
        ) {
          this.evaluation.order = assessorTypes;
        }
        this.$router.push(this.url);
      } else {
        this.$router.push(this.url);
      }
    },
    getFirstAssessorTypeByGroup(group) {
      return this.evaluation.order.find((assessorType) => {
        return group.assessor_list.find((groupAssessorType) => {
          return groupAssessorType == assessorType;
        });
      });
    },
    open(index) {
      this.tab = index;
      setTimeout(() => {
        $("#groupAccordion" + index).toggleClass("collapsing");
      }, 500);
    },
    checkAll() {
      this.newGroup.assessor_list = [];
      if (!this.CheckAll) {
        this.newGroup.assessor_list =
          this.$store.state.assessorTypes.map(
            (assessorType) => assessorType.id
          );
      }

      this.CheckAll =
        this.newGroup.assessor_list.length ===
        this.$store.state.assessorTypes.length;
      this.showTransitionPopup();
    },
    checkIndividual() {
      this.CheckAll =
        this.newGroup.assessor_list.length ===
        this.$store.state.assessorTypes.length;
      this.showTransitionPopup();
    },
    async addNewGroup() {
      this.isCheckAll = false;
      this.newGroup.assessor_list.length === 0
        ? (this.checkboxPermission = true)
        : (this.checkboxPermission = false);

      this.$validator.validateAll().then(async (result) => {
        if (this.checkboxPermission === true) {
          result = false;
        }
        if (result === true) {
          this.errorMessage = false;
          if (!this.evaluation.groups) {
            this.evaluation.groups = {};
          }
          let newAssessor;
          // this.evaluation.groups[this.newGroup.id] = this.newGroup;
          this.checkboxPermission = false;
          this.newGroup.assessors = {};
          this.newGroup.assessor_list.forEach((type) => {
            newAssessor = initializeAssessorDataByType(this, type);
            this.newGroup.assessors[newAssessor.id] = newAssessor;
          });
          
          
          
          this.newGroup.data.milkProduction =
          this.newGroup.data.measurements === 'imperial'
          ? this.imperialMilkProduction
          : this.metricMilkProduction;
          

          // this.newGroup.data.imperialMilkProduction = this.imperialMilkProduction
          // this.newGroup.data.metricMilkProduction = this.metricMilkProduction

         



          this[GET_CREATE_GROUP]({
            group: this.newGroup,
            evaluationId: this.paramEvaluationId,
          });

          this.CheckAll = false;
          this.confirmMessage = true; 

          setTimeout(() => {
            this.confirmMessage = false;
          }, 5000);

          if (this.evaluation.order) {
            const difference = _.differenceWith(
              this.newGroup.assessor_list,
              this.evaluation.order,
              _.isEqual
            );

            if (
              this.evaluation.order !== undefined &&
              difference.length !== 0 &&
              !this.evaluation.order.includes(difference)
            ) {
              this.evaluation.order =
                this.evaluation.order.concat(difference);
            }
          }

          this.$validator.pause();

          this.initializeNewGroup();
          this.$validator.reset();
        } else {
          this.errorMessage = true;
          setTimeout(() => {
            this.errorMessage = false;
          }, 5000);
        }
      });
    },
    async deleteGroup(groupId) {
      this[GET_DELETE_GROUP]({
        evaluationId: this.paramEvaluationId,
        groupId,
      });
      console.log("eval", this.evaluation);

      this[GET_UPDATE_EVALUATION](this.evaluation);
      this.$forceUpdate();
    },
    showTransitionPopup() {
      if (
        this.newGroup.assessor_list.includes("19") &&
        !this.newGroup.assessor_list.includes("3")
      ) {
        if (!this.showedTransitionAlert) {
          this.showTransitionAlert = true;
          this.showedTransitionAlert = true;
        }
        if (!this.newGroup.assessor_list.includes("3")) {
          this.newGroup.assessor_list.push("3");
        }
      }
    },
  },
  async created() {
    // if (!this.evaluation._id !== this.paramEvaluationId)
    setTimeout(() => {
      this.evaluation = this[GET_EVALUATION_BY_ID_GETTER](
        this.paramEvaluationId
      );
      if (!this.evaluation.groups) this.evaluation.groups = {};

      this.initializeNewGroup();

      this.assessorTypes = this.$store.state.assessorTypes;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.assessors-list {
  label {
    font-size: 13px;
  }

  .col-md-8 {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
